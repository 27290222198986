import { type UFOExperience } from '@atlassian/ufo';

import HttpError from '../../../utils/fetch/errors/HttpError';
import { type ActivitiesData } from '../../types';

import { packageName, packageVersion } from './package-context';

import { recentWorkClientExperienceV2, recentWorkClientExperienceV3 } from './index';

export async function fetchActivitiesWithExperience(
	fetchActivities: (experience: UFOExperience) => Promise<ActivitiesData>,
	clientVersion: 'v2' | 'v3',
) {
	const experience =
		clientVersion === 'v2' ? recentWorkClientExperienceV2() : recentWorkClientExperienceV3();
	try {
		experience.start();
		experience.addMetadata({ clientVersion, packageName, packageVersion });

		const data = await fetchActivities(experience);
		experience.success();
		return data;
	} catch (e) {
		experience.failure({
			metadata: {
				error: exceptionToMetadata(e),
			},
		});
		throw e;
	}
}

type UFOCustomData = {
	[key: string]: string | number | boolean | UFOCustomData | undefined;
};
export const exceptionToMetadata = (e: unknown): UFOCustomData | string => {
	if (e instanceof HttpError) {
		return {
			...e,
			xRequestId: e.xRequestId ?? undefined,
			cause: undefined,
		};
	}
	return e instanceof Error ? { ...e, cause: undefined } : 'unknown';
};
