import { type ActivitiesData, type ActivityFilters } from '../../../common/types';
import { type ActivityItem, type RootContainerIdentifier } from '../../../common/types/query';

import { type GraphQLError, type GraphQLResponse } from './types';
import { type Activity, type ActivityConnection, type Query } from './types/generated';

export function rollupActivitiesData(activitiesData: ActivitiesData): ActivitiesData {
	const { all, viewed, workedOn } = activitiesData;
	return {
		all: rollUpActivityItems(all),
		viewed: rollUpActivityItems(viewed),
		workedOn: rollUpActivityItems(workedOn),
	};
}

function rollUpActivityItems(activityItems: ActivityItem[]) {
	// https://stackoverflow.com/a/49460534
	// creates an ordered 'hashmap' containing unique ids in the same order that the activity items appear
	const latestActivityItemsObjectWithLatestTimestamp = activityItems.reduce(
		(acc: { [key in string]: ActivityItem }, activityItem: ActivityItem) => {
			const deduplicateId = activityItem.object.deduplicateId || activityItem.object.id;

			const existingItem = acc[deduplicateId];
			if (existingItem) {
				const aggregatedItems = existingItem.aggregatedActivityItems || [];

				if (Date.parse(existingItem.timestamp) < Date.parse(activityItem.timestamp)) {
					activityItem.aggregatedActivityItems = [...aggregatedItems, existingItem];
					// remove the current activity for that id so when we add the more recent activity it is in the order that it appears
					delete acc[deduplicateId];
					acc[deduplicateId] = activityItem;
				} else {
					acc[deduplicateId].aggregatedActivityItems = [...aggregatedItems, activityItem];
				}
			} else {
				acc[deduplicateId] = activityItem;
			}
			return acc;
		},
		{},
	);

	return Object.values(latestActivityItemsObjectWithLatestTimestamp);
}

/**
 * This wont extract the last fragment, but that's ok as it should be the activity item connection fragment
 * @param key
 */
export const extractFragmentRegex = (key: string): RegExp => {
	return new RegExp(
		`fragment ${fragmentName(key)} on ${fragmentType(key)} ({(.|\\n)+?(?=fragment))`,
		'gm',
	);
};

const fragmentName = (key: string): string => key.charAt(0).toLowerCase() + key.slice(1);

const fragmentType = (key: string): string => key.charAt(0).toUpperCase() + key.slice(1);

type ActivityConnections = {
	all?: ActivityConnection;
	workedOn?: ActivityConnection;
};

export const activityRoot = (activity: Activity): ActivityConnections => {
	if (activity.myActivity) {
		return activity.myActivity;
	}
	return activity;
};

const SITE_ARI_PREFIX = 'ari:cloud:platform::site/';
const containerMapTypes = new Map<string, RootContainerIdentifier['type']>([
	[SITE_ARI_PREFIX, 'SITE'],
]);

export function parseRootContainerARI(rootContainerARI: string): RootContainerIdentifier {
	// the "ID" portion of the ARI begins after the first '/'
	const ariSplitIndex = rootContainerARI.indexOf('/');
	return {
		ari: rootContainerARI,
		id: rootContainerARI.slice(ariSplitIndex + 1),
		type: containerMapTypes.get(rootContainerARI.slice(0, ariSplitIndex + 1)) || 'OTHER',
	};
}

// this is copied from @atlassian/ufo as it isn't exported
type CustomData = {
	[key: string]: string | number | boolean | CustomData | undefined;
};

export function filtersToMetadata(filters: ActivityFilters): CustomData {
	return {
		...filters,
		// These have V high cardinality, so using a length
		actors: filters.actors?.length,
		containerIds: filters.containerIds?.length,
		// comma separated lists
		products: filters.products?.join(','),
		eventTypes: filters.eventTypes?.join(','),
		objectTypes: filters.objectTypes?.join(','),
		rootContainerIds: filters.rootContainerIds?.join(','),
	};
}

/**
 * These fragments are the core of our query, without them it will always fail,
 * so we shouldn't remove them. It is possible that removing other offending fragments
 * will fix the error in these core fragments. (e.g. Field conflict)
 */
const RESTRICTED_FRAGMENTS = [
	'activity',
	'all',
	'myActivity',
	'workedOn',
	'viewed',
	'activityItemConnection',
	'activityItemEdge',
];

export function filterInvalidFragments(invalidFragments: string[], allFragments: string) {
	return invalidFragments
		.filter((fragment) => !RESTRICTED_FRAGMENTS.includes(fragment))
		.reduce<string>((acc, invalidFragment) => {
			return acc
				.replace(new RegExp(`...${invalidFragment}\n`, 'g'), '')
				.replace(extractFragmentRegex(invalidFragment), '');
		}, allFragments);
}

const IGNORED_ERROR_CLASSIFICATIONS = ['IncorrectAuthException'];

export function isEmptyActivitiesWithErrorsResponse(
	response?: GraphQLResponse,
): response is Omit<GraphQLResponse, 'errors'> & { errors: GraphQLError[] } {
	if (!response?.data || !Array.isArray(response.errors)) {
		return false;
	}

	const filteredAggErrors = response.errors.filter(
		(e) =>
			!e.extensions.classification ||
			!IGNORED_ERROR_CLASSIFICATIONS.includes(e.extensions.classification),
	);

	return filteredAggErrors.length > 0 && isEmptyActivityResponse(response.data);
}

export function isEmptyActivityResponse(queryData: Query) {
	return (
		!queryData.activity ||
		(activityRoot(queryData.activity).all?.edges?.length === 0 &&
			activityRoot(queryData.activity).workedOn?.edges?.length === 0 &&
			queryData.activity?.myActivity?.viewed?.edges?.length === 0)
	);
}
