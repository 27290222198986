import { type ActivityQuery } from '../../../common/types';
import { type ActivityFilters } from '../../../common/types/options/activity-filters';
import { type ActivitiesData, GRAPHQL_GATEWAY_PATH } from '../../../common/types/query';

export abstract class ActivityClient {
	clientVersion: string;
	product: string = 'unknown';
	headers: Record<string, string>;
	baseUrl: string = GRAPHQL_GATEWAY_PATH;

	constructor(
		clientVersion: string,
		product?: string,
		headers?: Record<string, string>,
		baseUrl?: string,
	) {
		if (product) {
			this.product = product;
		}
		this.clientVersion = clientVersion;
		this.headers = headers ?? {};

		if (baseUrl) {
			this.baseUrl = baseUrl;
		}
	}

	abstract fetchActivities: (
		retryMS: number[],
		filters: ActivityFilters,
		activityQueries: ActivityQuery[],
		deduplicateActivities?: boolean,
	) => Promise<ActivitiesData>;

	abstract setProduct: (product: string) => void;
}
