import { type GraphQLError } from '../../../services/activity-client/v3/types';

export class GraphQLJSError extends Error {
	error: GraphQLError;

	constructor(graphQLError: GraphQLError) {
		super(graphQLError.message);
		this.name = 'GraphQLJSError';
		this.error = graphQLError;
		this.message = graphQLError.message;
	}
}
