import { type ActivityFilters } from '../../../../../common/types';
import { type ActivityQueryArgumentsV2 } from '../../../../../common/types/options/activity-filters';
import { type ActivityQueryFilterV2 } from '../../index';
import { generateActivityFilters } from '../../utils';

// in V2 API the values for certain filters should be in UPPERCASE as are ENUMs in the GraphQL
// likewise for the V3 handler we should ensure we do appropriate lowercase conversion
const FILTERS_TO_TRANSFORM__UPPERCASE: (keyof ActivityFilters)[] = [
	'products',
	'eventTypes',
	'objectTypes',
];

const KEYS_TO_TRANSFROM = new Map<keyof ActivityFilters, keyof ActivityQueryArgumentsV2>([
	['actors', 'accountIds'],
]);

export const generateActivityFiltersV2 = <
	Arguments extends ActivityQueryArgumentsV2,
	T extends ActivityQueryFilterV2<Arguments>,
>(
	filters: ActivityFilters = {},
) =>
	generateActivityFilters<Arguments, T>(filters, (originalkey, originalFilter) => {
		const filter =
			Array.isArray(originalFilter) &&
			FILTERS_TO_TRANSFORM__UPPERCASE.includes(originalkey as keyof ActivityFilters)
				? originalFilter.map<string>((f) => f.toUpperCase())
				: originalFilter;

		const key = KEYS_TO_TRANSFROM.has(originalkey as keyof ActivityFilters)
			? KEYS_TO_TRANSFROM.get(originalkey as keyof ActivityFilters)!
			: originalkey;

		return { [key]: filter };
	});
