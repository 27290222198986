import {
	type ActivitiesData,
	type ActivityFilters,
	type ActivityQuery,
} from '../../../common/types';
import { fetchActivitiesWithExperience } from '../../../common/utils/analytics/utils';
import { ActivityClient } from '../base';

import { fetchActivitiesV2 } from './fetch-activities';

export class ActivityClientV2 extends ActivityClient {
	constructor(product?: string, baseUrl?: string) {
		super('v2', product, undefined, baseUrl);
	}

	fetchActivities = async (
		retryMS: number[],
		filters: ActivityFilters,
		activityQueries: ActivityQuery[],
	): Promise<ActivitiesData> => {
		return fetchActivitiesWithExperience(
			(experience) =>
				fetchActivitiesV2({
					experience,
					product: this.product,
					retryMS,
					filters,
					activityQueries,
					baseUrl: this.baseUrl,
				}),
			'v2',
		);
	};

	setProduct = (product: string) => {
		this.product = product;
	};
}
