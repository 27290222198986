import generateV3ActivityFilters from './activities';
import generateV3MyActivityFilters from './myActivity';

/**
 * Map of available V3 query filters.
 */
export const ACTIVITY_FILTER_V3_MAP: ReadonlyMap<
	'myActivity' | 'activities',
	typeof generateV3ActivityFilters | typeof generateV3MyActivityFilters
> = new Map([
	['myActivity', generateV3MyActivityFilters],
	['activities', generateV3ActivityFilters],
]);
