import { type UFOExperience } from '@atlassian/ufo';

import { type ActivityFilters, type ActivityQuery } from '../../../../common/types';
import {
	type ActivitiesData,
	type ActivitiesDataTabsConnection,
	type ActivitiesTabsActivitiesConnection,
	type ActivitiesTabsMyActivitiesConnection,
	type ActivityItem,
	type GraphQLRequest,
} from '../../../../common/types/query';
import { getOperationName } from '../../../../common/utils/operation';
import { postResource } from '../../../../utils/fetch';

import { generateActivityQueryWithFilters } from './activities-query';
import { transformActivitiesItems } from './activities-transform';

const ACTIVITIES_LIMIT = 50;

// typescript guard for validating the activities connection type
const isMyActivitiesConnection = (
	activities: ActivitiesTabsMyActivitiesConnection | ActivitiesTabsActivitiesConnection,
): activities is ActivitiesTabsMyActivitiesConnection => {
	return (activities as ActivitiesTabsMyActivitiesConnection).myActivities !== undefined;
};

const V2_AVAILABLE_PRODUCTS = [
	'teamCentral',
	'editor',
	'people',
	'jira',
	'townsquare',
	'apOnboarding',
	'worklens',
	'confluence',
	'start',
];

export const fetchActivitiesV2 = async ({
	experience,
	product,
	retryMS,
	filters = {},
	activityQueries,
	baseUrl,
}: {
	experience: UFOExperience;
	product: string;
	retryMS: number[];
	filters: ActivityFilters;
	activityQueries: ActivityQuery[];
	baseUrl: string;
}): Promise<ActivitiesData> => {
	const {
		// we want to default limit if not applied
		limit = ACTIVITIES_LIMIT,
		...restFilters
	} = filters;

	experience.mark('GeneratedQueryWithFilters_start');

	const { query, queryFilters } = generateActivityQueryWithFilters(
		product,
		restFilters,
		activityQueries,
	);
	experience.mark('GeneratedQueryWithFilters_end');

	// This is to make v2 forwards compatible. Defaulting to start as the product
	const operationProduct = V2_AVAILABLE_PRODUCTS.includes(product) ? product : 'start';
	experience.mark('FetchedActivities_start');

	let response;
	try {
		response = await postResource<ActivitiesDataTabsConnection, GraphQLRequest>(
			baseUrl,
			{
				operationName: getOperationName(operationProduct),
				query,
				variables: {
					first: limit,
					filters: queryFilters,
				},
			},
			retryMS,
		);
	} catch (error: any) {
		if (!error || (error.statusCode !== 401 && error.message !== 'The operation was aborted.')) {
			throw error;
		}
	}

	experience.mark('FetchedActivities_end');

	let workedOn: ActivityItem[] = [],
		viewed: ActivityItem[] = [],
		all: ActivityItem[] = [];

	if (!response || !response.data?.activities) {
		return { all, workedOn, viewed };
	}
	experience.mark('TransformedActivityItems_start');

	if (isMyActivitiesConnection(response.data.activities)) {
		if (response.data.activities.myActivities.workedOn && activityQueries.includes('workedOn')) {
			workedOn = transformActivitiesItems(
				'workedOn',
				response.data.activities.myActivities.workedOn,
			);
		}
		if (response.data.activities.myActivities.viewed && activityQueries.includes('viewed')) {
			viewed = transformActivitiesItems('viewed', response.data.activities.myActivities.viewed);
		}
		if (response.data.activities.myActivities.all && activityQueries.includes('all')) {
			all = transformActivitiesItems('all', response.data.activities.myActivities.all);
		}
	} else {
		if (response.data.activities.workedOn && activityQueries.includes('workedOn')) {
			workedOn = transformActivitiesItems('workedOn', response.data.activities.workedOn);
		}
		if (response.data.activities.all && activityQueries.includes('all')) {
			all = transformActivitiesItems('all', response.data.activities.all);
		}
	}
	experience.mark('TransformedActivityItems_end');

	return { all, viewed, workedOn };
};
