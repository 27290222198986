import type { FlightDeckConfig } from '../hydrator-config-client';

import { type ActivityClient } from './base';
import { ActivityClientV2 } from './v2';
import { ActivityClientV3 } from './v3';

export type ActivityClientVersion = 'v2' | 'v3';

export type ClientHandlers = {
	captureException: (error: Error, tags?: Record<string, string>) => void;
	captureWarning: (warning: string, tags?: Record<string, string>) => void;
};

export type CreateActivityClient = (
	version?: ActivityClientVersion | undefined,
	product?: string | undefined,
	logger?: ClientHandlers | undefined,
	baseUrl?: string | undefined,
	configFetch?: () => Promise<FlightDeckConfig>,
) => ActivityClient;

let previousClientVersion: ActivityClientVersion;

export const createActivityClient: CreateActivityClient = (
	version,
	product,
	logger,
	baseUrl,
	configFetch,
) => {
	if (previousClientVersion && previousClientVersion !== version && logger?.captureWarning) {
		logger.captureWarning(`ActivityClient version has changed, is this intentional?`);
	}
	previousClientVersion = version || 'v3';

	if (version === 'v2') {
		return new ActivityClientV2(product, baseUrl);
	}

	return new ActivityClientV3(product, baseUrl, logger, configFetch);
};
