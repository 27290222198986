import { type ObjectIcon } from '../../common/types/query';

import { type HydratedData, type HydratedDataIcon, type HydratedEmoji } from './types';

export const hydratedValueToString = (
	value: string | string[] | null | undefined,
	separator: string = '',
): string | null => {
	if (!value) {
		return null;
	}

	return Array.isArray(value) ? value.join(separator) : value;
};

function isURL(icon: HydratedDataIcon): icon is string {
	return typeof icon === 'string';
}

function isValidEmoji(icon: HydratedDataIcon): icon is HydratedEmoji {
	return typeof icon === 'object' && icon.value !== undefined && !!icon.value;
}

export const hydrateConfluenceIcon = (
	confluenceContainerType: 'ConfluenceBlogPost' | 'ConfluencePage' | undefined,
	icons: HydratedData['icon'],
	fallbacks?: HydratedData['staticFallbacks'],
): ObjectIcon | null => {
	switch (confluenceContainerType) {
		case 'ConfluenceBlogPost':
			return hydrateIcon(icons, fallbacks, 'confluenceBlogIcon') || hydrateIcon(icons, fallbacks);
		case 'ConfluencePage':
			return hydrateIcon(icons, fallbacks, 'confluencePageIcon') || hydrateIcon(icons, fallbacks);
		default:
			return hydrateIcon(icons, fallbacks);
	}
};

export const hydrateIcon = (
	icons: HydratedData['icon'],
	fallbacks?: HydratedData['staticFallbacks'],
	fallbackKey: keyof Required<HydratedData>['staticFallbacks'] = 'icon',
): ObjectIcon | null => {
	if (Array.isArray(icons) && icons.length > 0) {
		const emojiIcons: HydratedEmoji[] = icons.filter(isValidEmoji);
		if (emojiIcons.length > 0) {
			return {
				type: 'EMOJI',
				value: emojiIcons[0].value!, // return the first emoji
				fallback: fallbacks?.[fallbackKey],
			};
		}

		const urlIcons: string[] = icons.filter(isURL);
		if (urlIcons.length > 0) {
			const hydratedIcons = hydratedValueToString(urlIcons);
			if (hydratedIcons !== null) {
				return {
					type: 'URL',
					value: hydratedIcons,
				};
			}
		}
	}

	if (fallbacks && fallbackKey in fallbacks && fallbacks[fallbackKey]) {
		return {
			type: 'URL',
			value: fallbacks[fallbackKey] as string,
		};
	}

	return null;
};
