import { type ActivityFilters } from '../../../../../common/types';
import { type ActivityQueryArgumentsV3 } from '../../../../../common/types/options/activity-filters';
import { type ActivityFilterGenerator, type ActivityQueryFilterV3 } from '../../index';
import { generateActivityFiltersV3 } from '../utils';

type ActivityQueryFilterV3MyActivity = Omit<ActivityQueryFilterV3, 'actors' | 'limit'>;

/**
 * This is the query builder for the v3 - myActivity API
 *
 * @param filters
 */
const generateV3MyActivityFilters: ActivityFilterGenerator<
	ActivityQueryArgumentsV3,
	ActivityQueryFilterV3MyActivity
> = (filters: ActivityFilters = {}) => {
	const {
		// discard as these aren't supported by this query
		limit,
		actors,
		...restFilters
	} = filters;

	return generateActivityFiltersV3<ActivityQueryArgumentsV3, ActivityQueryFilterV3MyActivity>(
		restFilters,
	);
};

export default generateV3MyActivityFilters;
