import { type GraphQLError } from '../types';

import validationErrorHandler from './validation-error';

type ErrorHandler = (graphQLError: GraphQLError) => string | null;

const errorHandlerMap = new Map<string, ErrorHandler>([
	['ValidationError', validationErrorHandler],
]);

const graphQLErrorHandler = (err: GraphQLError): string | null => {
	const errorHandler = errorHandlerMap.get(err.extensions.classification);
	if (typeof errorHandler === 'function') {
		return errorHandler(err);
	}

	return null;
};

export default graphQLErrorHandler;
