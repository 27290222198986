import { type GraphQLError } from '../../../services/activity-client/v3/types';

export class GraphQLNoResultsError extends Error {
	errors: GraphQLError[];
	errorMessages: string[];

	constructor(graphQLErrors: GraphQLError[]) {
		super();
		this.name = 'GraphQLNoResultsError';
		this.errors = graphQLErrors;
		this.message = 'GraphQL returned errors & no results';
		this.errorMessages = graphQLErrors.map((error) => error.message);
	}
}
