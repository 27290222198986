import generateV2ActivitiesFilters from './activities';
import generateV2MyActivityFilters from './myActivity';

/**
 * Map of available V2 query filters.
 */
export const ACTIVITY_FILTER_V2_MAP: ReadonlyMap<
	'myActivity' | 'activities',
	typeof generateV2ActivitiesFilters | typeof generateV2MyActivityFilters
> = new Map([
	['myActivity', generateV2MyActivityFilters],
	['activities', generateV2ActivitiesFilters],
]);
