import { type ActivityFilters } from '../../../../../common/types/options/activity-filters';
import { type ActivityQuery } from '../../../../../common/types/options/activity-queries';
import { getOperationName } from '../../../../../common/utils/operation';
import { ACTIVITY_FILTER_COLLECTION, type ActivityQueryFilterV2 } from '../../../activity-filters';

const ACTIVITIES = `
  nodes {
    id
    timestamp
    object {
      id
      name
      cloudId
      localResourceId
      product
      type
      url
      iconUrl
      containers {
        name
      }
      contributors {
        profile {
          accountId
          name
          picture
        }
      }
      events {
        eventType
      }
      extension {
        ... on ActivitiesJiraIssue {
          issueKey
        }
      }
    }
  }
`;

const WORKED_ON_QUERY = `
   workedOn(first: $first, filters: $filters) {
     ${ACTIVITIES}
   }
`;

const VIEWED__QUERY = `
   viewed(first: $first, filters: $filters) {
     ${ACTIVITIES}
   }
`;

const ALL__QUERY = `
    all(first: $first, filters: $filters) {
      ${ACTIVITIES}
    }
`;

const activityQueryMap = new Map<
	ActivityQuery,
	typeof WORKED_ON_QUERY | typeof VIEWED__QUERY | typeof ALL__QUERY
>([
	['workedOn', WORKED_ON_QUERY],
	['viewed', VIEWED__QUERY],
	['all', ALL__QUERY],
]);

const getMyActivitiesQuery = (
	queries: (typeof WORKED_ON_QUERY | typeof VIEWED__QUERY | typeof ALL__QUERY)[],
) => `
      myActivities {
        ${queries.join('\n')}
      }
`;

const getActivitiesQuery = (product: string, content: string): string => `
    query ${getOperationName(product)}($first: Int, $filters: [ActivitiesFilter!]) {
      activities {
        ${content}
      }
    }
`;

export const generateActivityQueryWithFilters = (
	product: string,
	filters: ActivityFilters,
	activityQueries: ActivityQuery[],
): { query: string; queryFilters: ActivityQueryFilterV2 } => {
	/**
	 * If we are filtering by `accountIds` then we can't use the `myActivity` query.
	 */
	if (Array.isArray(filters.actors)) {
		// you can't view `viewed` events for other accounts
		const filteredAccountQueries = activityQueries.filter((query) => query !== 'viewed');

		return {
			query: getActivitiesQuery(
				product,
				filteredAccountQueries.map((query) => activityQueryMap.get(query)!).join('\n'),
			),
			queryFilters: ACTIVITY_FILTER_COLLECTION.v2.get('activities')!(filters),
		};
	} else {
		return {
			query: getActivitiesQuery(
				product,
				getMyActivitiesQuery(activityQueries.map((query) => activityQueryMap.get(query)!)),
			),
			queryFilters: ACTIVITY_FILTER_COLLECTION.v2.get('myActivity')!(filters),
		};
	}
};
