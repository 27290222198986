import { type ActivityFilters } from '../../../../../common/types';
import { type ActivityQueryArgumentsV3 } from '../../../../../common/types/options/activity-filters';
import { type ActivityFilterGenerator, type ActivityQueryFilterV3 } from '../../index';
import { generateActivityFiltersV3 } from '../utils';

type ActivityQueryFilterV3Activity = ActivityQueryFilterV3;

/**
 * This is the query builder for the v3 - activities API
 *
 * @param filters
 */
const generateV3ActivityFilters: ActivityFilterGenerator<
	ActivityQueryArgumentsV3,
	ActivityQueryFilterV3Activity
> = (filters: ActivityFilters = {}) => {
	const {
		// discard as these aren't supported by this query
		limit,
		...restFilters
	} = filters;

	return generateActivityFiltersV3<ActivityQueryArgumentsV3, ActivityQueryFilterV3Activity>(
		restFilters,
	);
};

export default generateV3ActivityFilters;
