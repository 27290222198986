import type { ActivityQuery } from '../options/activity-queries';

export const GRAPHQL_GATEWAY_PATH = '/gateway/api/graphql';

export interface GraphQLRequest {
	query: string;
	operationName?: string;
	variables?: {};
}

export interface Container {
	name: string;
}

export interface Contributor {
	accountId: string;
	picture: string;
	name: string;
}

export type ObjectIcon = URLIcon | EmojiIcon;

interface CommonIcon {
	value: string;
}

interface URLIcon extends CommonIcon {
	type: 'URL';
}

interface EmojiIcon extends CommonIcon {
	type: 'EMOJI';
	fallback?: string;
}

export type RootContainerIdentifier = {
	id: string;
	ari: string;
	type: 'SITE' | 'OTHER';
};

export interface ObjectItem {
	rootContainerIdentifier: RootContainerIdentifier;
	id: string;
	localResourceId: string | null; // this is needed by Search to de-duplicate results
	name: string | null; // sometimes a draft will come in with a `null` name
	product: string; // right now this could be 'jira'
	subProduct: string | null; // eg 'software'
	type: ActivityObjectType;
	subtype?: string;
	url: string;
	icon: ObjectIcon | null;
	deduplicateId: string;
	objectTypeName?: string | null; // e.g. 'Bug', 'Story', 'Task'
}

export interface ActivityItem {
	containers: Container[];
	contributors: Contributor[];
	eventType: ActivityEventType;
	object: ObjectItem;
	query: ActivityQuery;
	timestamp: string;
	actor?: Contributor;
	aggregatedActivityItems?: ActivityItem[];
}

export interface GraphQLRequest {
	operationName?: string;
	query: string;
	variables?: {};
}

export interface ActivitiesEvent {
	eventType: ActivityEventType;
}

export interface ActivitiesJiraIssue {
	issueKey: string;
}

export type ActivitiesExtension = ActivitiesJiraIssue;

export interface ActivitiesObject {
	cloudId: string;
	events: ActivitiesEvent[];
	extension?: ActivitiesExtension;
	iconUrl: string;
	id: string;
	localResourceId: string;
	name: string;
	product: string; // right now this could be 'jira'
	type: ActivityObjectType;
	url: string;
	containers?: Container[];
	contributors?: ContributorConnection[];
}

export interface ContributorConnection {
	profile: {
		accountId: string;
		picture: string;
		name: string;
	} | null;
}

export interface ActivitiesConnectionItem {
	id: string;
	object: ActivitiesObject;
	timestamp: string;
	actor?: {
		accountId: string;
		picture: string;
		name: string;
	};
}

export interface ActivitiesConnection {
	nodes?: ActivitiesConnectionItem[];
}
export interface ActivitiesTabsMyActivitiesConnection {
	myActivities: {
		all?: ActivitiesConnection | null;
		viewed?: ActivitiesConnection | null;
		workedOn?: ActivitiesConnection | null;
	};
}
export interface ActivitiesTabsActivitiesConnection {
	all?: ActivitiesConnection | null;
	workedOn?: ActivitiesConnection | null;
}

export interface ActivitiesTabsConnection {
	activities: ActivitiesTabsMyActivitiesConnection | ActivitiesTabsActivitiesConnection | null;
	errors?: {
		message: string;
	}[];
}

export interface ActivitiesDataTabsConnection {
	data?: ActivitiesTabsConnection;
}

export enum VIEWED_EVENT {
	VIEWED = 'viewed',
}

export enum WORKED_EVENT {
	COMMENTED = 'commented',
	CREATED = 'created',
	EDITED = 'edited',
	PUBLISHED = 'published',
	UPDATED = 'updated',
}

export type ActivityEventType = VIEWED_EVENT | WORKED_EVENT;

export enum ActivityObjectType {
	BLOGPOST = 'BLOGPOST',
	COMMENT = 'COMMENT',
	ISSUE = 'ISSUE',
	PAGE = 'PAGE',
	PROJECT = 'PROJECT',
	SITE = 'SITE',
	SPACE = 'SPACE',
	TASK = 'TASK',
}

type ActivitiesDataQuery = 'all' | 'viewed' | 'workedOn';

export type ActivitiesData = Record<ActivitiesDataQuery, ActivityItem[]>;
