import { type ActivityFilters } from '../../../../common/types';
import { type ActivityQueryFilterArgumentsCommon } from '../../../../common/types/options/activity-filters';
import { type ActivityFilterGenerator, type ActivityQueryFilterCommon } from '../index';

export const generateActivityFilters = <
	Arguments extends ActivityQueryFilterArgumentsCommon,
	Filter extends ActivityQueryFilterCommon<Arguments>,
>(
	filters: ActivityFilters = {},
	transform?: (key: keyof ActivityFilters, filter: any) => Record<string, any>,
	rootLevelFilters?: string[],
) => {
	return Object.entries(filters).reduce<ReturnType<ActivityFilterGenerator<Arguments, Filter>>>(
		(acc, [key, filter]) => {
			const entry = transform ? transform(key as keyof ActivityFilters, filter) : { [key]: filter };

			return rootLevelFilters?.includes(key)
				? {
						...acc,
						...entry,
					}
				: {
						...acc,
						arguments: {
							...acc.arguments,
							...entry,
						},
					};
		},
		{
			type: 'AND',
			arguments: {},
		} as Filter,
	);
};
