import { type ActivityFilters } from '../../../../../common/types';
import { type ActivityQueryArgumentsV2 } from '../../../../../common/types/options/activity-filters';
import { type ActivityFilterGenerator, type ActivityQueryFilterV2 } from '../../index';
import { generateActivityFiltersV2 } from '../utils';

type MyActivityArguments = Omit<ActivityQueryArgumentsV2, 'accountIds'>;
type ActivityQueryFilterV2MyActivity = ActivityQueryFilterV2<MyActivityArguments>;

/**
 * This is the query builder for the v2 - myActivity API
 *
 * @param filters
 */
const generateV2MyActivityFilters: ActivityFilterGenerator<
	MyActivityArguments,
	ActivityQueryFilterV2MyActivity
> = (filters: ActivityFilters = {}) => {
	const {
		// discard as these aren't supported by this query
		limit,
		actors,

		// TODO: Enable the below code as we support multi-user queries and enable them in the main ActivityFilters interface
		// actors,
		// rootContainerIds,
		...restFilters
	} = filters;

	return generateActivityFiltersV2<MyActivityArguments, ActivityQueryFilterV2MyActivity>(
		restFilters,
	);
};

export default generateV2MyActivityFilters;
