export interface HttpErrorProps {
	message: string;
	method: string;
	path: string;
	statusCode: number;
	xRequestId: string | null;
	headers: Headers;
}

export default class HttpError extends Error {
	message: string;
	method: string;
	path: string;
	statusCode: number;
	xRequestId: string | null;
	headers: string;

	constructor({ message, statusCode, path, method, xRequestId, headers }: HttpErrorProps) {
		super();
		this.name = 'HttpError';
		this.message = message;
		const p = path.split('?')[0];
		this.path = /^[/-a-z]+$/i.test(p) ? p : '';
		this.method = method;
		this.statusCode = statusCode;
		this.xRequestId = xRequestId;
		// @ts-ignore - Property 'entries' does not exist on type 'Headers'.
		// This was added when enrolling `@atlassian/recent-work-client` into JFE local consumption
		this.headers = Array.from(headers.entries())
			.map((value, key) => `${key}:${value}`)
			.sort()
			.join(',');
	}
}

export const isHttpError = (e: unknown): boolean => e instanceof HttpError;
