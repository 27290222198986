import {
	type ActivityFilters,
	type ActivityQueryArgumentsV2,
	type ActivityQueryArgumentsV3,
	type ActivityQueryFilterArgumentsCommon,
} from '../../../common/types/options/activity-filters';

import { ACTIVITY_FILTER_V2_MAP } from './v2';
import { ACTIVITY_FILTER_V3_MAP } from './v3';

export type ActivityQueryFilterCommon<
	T extends ActivityQueryFilterArgumentsCommon = ActivityQueryFilterArgumentsCommon,
> = {
	type: 'AND' | 'OR';
	arguments: T;
};

export type ActivityQueryFilterV2<T extends ActivityQueryArgumentsV2 = ActivityQueryArgumentsV2> =
	ActivityQueryFilterCommon<T>;

export type ActivityQueryFilterV3<T extends ActivityQueryArgumentsV3 = ActivityQueryArgumentsV3> =
	ActivityQueryFilterCommon<T> & {
		/** Set of actor ARIs whose activity should be searched (ex: AAIDs) - Note this only used on the V3 API */
		actors?: string[];

		/** Set of top-level container ARIs (ex: Cloud ID, workspace ID) */
		rootContainerIds?: string[];
	};

export type ActivityFilterGenerator<
	Arguments extends ActivityQueryFilterArgumentsCommon,
	Filter extends ActivityQueryFilterCommon = ActivityQueryFilterCommon<Arguments>,
> = (filters?: ActivityFilters) => Filter;

/**
 * Map of available API versions and their collective activity filters.
 * You can test these against the `status-ui` repository with `yarn start-with-proxy` by linking the package.
 */
export const ACTIVITY_FILTER_COLLECTION: Readonly<{
	v2: typeof ACTIVITY_FILTER_V2_MAP;
	v3: typeof ACTIVITY_FILTER_V3_MAP;
}> = {
	v2: ACTIVITY_FILTER_V2_MAP,
	v3: ACTIVITY_FILTER_V3_MAP,
};
