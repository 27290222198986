import { type ActivityFilters } from '../../../../../common/types';
import { type ActivityQueryArgumentsV3 } from '../../../../../common/types/options/activity-filters';
import { type ActivityQueryFilterV3 } from '../../index';
import { generateActivityFilters } from '../../utils';

// in V2 API the values for certain filters should be in UPPERCASE as are ENUMs in the GraphQL
// likewise for the V3 handler we should ensure we do appropriate lowercase conversion
const FILTERS_TO_TRANSFORM__LOWERCASE: (keyof ActivityFilters)[] = [
	'products',
	'eventTypes',
	'objectTypes',
];

const ROOT_LEVEL_FILTERS = ['actors', 'rootContainerIds'];

export const generateActivityFiltersV3 = <
	Arguments extends ActivityQueryArgumentsV3,
	Filter extends ActivityQueryFilterV3<Arguments>,
>(
	filters: ActivityFilters = {},
) =>
	generateActivityFilters<Arguments, Filter>(
		filters,
		(key, filter) =>
			Array.isArray(filter) &&
			FILTERS_TO_TRANSFORM__LOWERCASE.includes(key as keyof ActivityFilters)
				? { [key]: filter.map<string>((f) => f.toLowerCase()) }
				: { [key]: filter },
		ROOT_LEVEL_FILTERS,
	);
