import { type ActivityQuery } from '../../../../../common/types';
import {
	type ActivitiesConnection,
	type ActivityEventType,
	type ActivityItem,
	type Container,
	type Contributor,
} from '../../../../../common/types/query';
const SITE_ARI_PREFIX = 'ari:cloud:platform::site/';

export function transformActivitiesItems(
	query: ActivityQuery,
	activities: ActivitiesConnection | null = {},
): ActivityItem[] {
	if (!activities || !activities.nodes) {
		return [];
	}

	return activities.nodes.map<ActivityItem>((item) => {
		const { object, timestamp } = item;
		const { cloudId, events, extension, iconUrl, id, localResourceId, name, product, url, type } =
			object;
		// V2 returns eventType captilised, V3 returns lowercase
		const eventType = events[0].eventType.toLowerCase() as ActivityEventType;
		const issueKey = extension && extension.issueKey;

		let containers: Container[] = [];
		if (issueKey) {
			containers.push({ name: issueKey });
		}
		if (Array.isArray(object.containers)) {
			containers = containers.concat(object.containers);
		}

		containers = containers.filter((container) => !!container.name);

		return {
			query,
			containers,
			contributors: (object.contributors || []).reduce<Contributor[]>(
				(contributors, contributor) => {
					if (contributor.profile) {
						const { accountId, picture } = contributor.profile;
						contributors.push({
							accountId,
							name: contributor.profile.name,
							picture,
						});
					}
					return contributors;
				},
				[] as Contributor[],
			),
			eventType,
			object: {
				rootContainerIdentifier: {
					id: cloudId,
					type: 'SITE',
					ari: `${SITE_ARI_PREFIX}${cloudId}`,
				},
				deduplicateId: id,
				icon: { type: 'URL', value: iconUrl },
				id,
				localResourceId,
				name,
				product,
				subProduct: null,
				type,
				url,
			},
			timestamp,
		};
	});
}
