import { type GraphQLError } from '../../types';

/**
 * ^Validation error - look for validation errors from the start
 * .*?@ ' - look through everything up to the first instance of "@ '"
 * (\w+) - capture the first word, that's our invalid fragment
 */
const captureRegex = new RegExp(/^Validation error.*?@ '(\w+)/);

const validationErrorHandler = (graphQLError: GraphQLError): string | null => {
	const invalidFragmentMatch = captureRegex.exec(graphQLError.message);
	if (Array.isArray(invalidFragmentMatch)) {
		const invalidFragment = invalidFragmentMatch[1];
		if (invalidFragment) {
			return invalidFragment;
		}
	}

	return null;
};

export default validationErrorHandler;
