import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

const keyToTiming = (key: string) => ({
	key,
	startMark: `${key}_start`,
	endMark: `${key}_end`,
});

const timings = ['FetchedActivities', 'TransformedActivityItems', 'GeneratedQueryWithFilters'].map(
	keyToTiming,
);

export const recentWorkClientExperienceV2 = () => {
	return new UFOExperience('client', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.Custom,
		platform: { component: 'recent-work' },
		timings,
	});
};
export const recentWorkClientExperienceV3 = () =>
	new UFOExperience('client-v3', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.Custom,
		platform: { component: 'recent-work' },
		timings,
	});
