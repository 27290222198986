import { FailedFetchError, retryOnException } from '@atlaskit/frontend-utilities/retry-operation';

import { getFullUrl } from './config';
import HttpError from './errors/HttpError';

const fetchResource = async (
	path: string,
	method: string,
	options: object = {},
): Promise<Response> => {
	const url = getFullUrl(path);
	const response = await fetch(url, {
		credentials: 'include',
		method,
		...options,
	}).catch((error) => {
		throw new FailedFetchError({ error, path, method });
	});
	const statusCode = response.status;

	if (!response.ok) {
		if (statusCode === 401) {
			// this.onUnauthorised();
		}

		const text = scrubTimestampFromErrorBody(await response.text());
		const httpError = new HttpError({
			message: text,
			method,
			path,
			statusCode,
			xRequestId: response.headers.get('x-request-id'),
			headers: response.headers,
		});
		throw httpError;
	}

	return response;
};

const makeJsonRquest = async <T>(
	path: string,
	method: string,
	additionalFetchOptions: object = {},
): Promise<T> => {
	const response = await fetchResource(path, method, additionalFetchOptions);
	const text = await response.text();
	// trying to parse empty response as JSON throws an error, but endpoints can return empty response in case of success
	return text ? JSON.parse(text) : {};
};

export const postResource = async <T, D>(
	path: string,
	data: D,
	retryMS?: number[],
	extraHeaders?: Record<string, string>,
): Promise<T> =>
	retryOnException(
		() =>
			makeJsonRquest<T>(path, 'POST', {
				body: JSON.stringify(data),
				headers: {
					'content-type': 'application/json',
					...extraHeaders,
				},
			}),
		{
			intervalsMS: retryMS ?? [],
		},
	);

// to dedup sentry, scrub non-unique 'timestamp' field from the error body
function scrubTimestampFromErrorBody(text: string) {
	let json;
	try {
		json = JSON.parse(text);
	} catch (err) {
		return text;
	}
	delete json.timestamp;
	return JSON.stringify(json);
}
