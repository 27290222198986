import { type ActivityFilters } from '../../../../common/types/options/activity-filters';
import { type ActivityQuery } from '../../../../common/types/options/activity-queries';
import { getOperationName } from '../../../../common/utils/operation';
import { ACTIVITY_FILTER_COLLECTION, type ActivityQueryFilterV3 } from '../../activity-filters';

const ACTIVITIES_LIMIT = 50;

export interface GraphQLRequest {
	operationName?: string;
	query: string;
	variables?: {
		first: number;
		after?: string;
		filter: ActivityQueryFilterV3;
	};
}

const WORKED_ON_QUERY = `
   workedOn(first: $first, filter: $filter) {
     ...activityItemConnection
   }
`;

const VIEWED__QUERY = `
   viewed(first: $first, filter: $filter) {
     ...activityItemConnection
   }
`;

const ALL__QUERY = `
    all(first: $first, filter: $filter) {
      ...activityItemConnection
    }
`;

const activityQueryMap = new Map<ActivityQuery, string>([
	['workedOn', WORKED_ON_QUERY],
	['viewed', VIEWED__QUERY],
	['all', ALL__QUERY],
]);

const getMyActivitiesQuery = (queries: string[]) => `
      myActivity {
        ${queries.join('\n')}
      }
`;

const getActivitiesQuery = (
	product: string,
	content: string,
	fragments: string,
	activityFilterType: 'MyActivityFilter' | 'ActivityFilter' = 'ActivityFilter',
): string => `
    query ${getOperationName(product)}($first: Int, $filter: ${activityFilterType}) {
      activity {
        ${content}
      }
    }
${fragments}
`;

export const generateActivityQueryWithFilters = (
	product: string,
	filters: ActivityFilters,
	activityQueries: ActivityQuery[],
	fragments: string,
): { query: string; queryFilters: ActivityQueryFilterV3 } => {
	/**
	 * If we are filtering by `actors` then we can't use the `myActivity` query.
	 */
	if (Array.isArray(filters.actors)) {
		// you can't view `viewed` events for other accounts
		const filteredAccountQueries = activityQueries.filter((query) => query !== 'viewed');

		return {
			query: getActivitiesQuery(
				product,
				filteredAccountQueries.map<string>((query) => activityQueryMap.get(query)!).join('\n'),
				fragments,
				'ActivityFilter',
			),
			queryFilters: ACTIVITY_FILTER_COLLECTION.v3.get('activities')!(filters),
		};
	} else {
		return {
			query: getActivitiesQuery(
				product,
				getMyActivitiesQuery(activityQueries.map<string>((query) => activityQueryMap.get(query)!)),
				fragments,
				'MyActivityFilter',
			),
			queryFilters: ACTIVITY_FILTER_COLLECTION.v3.get('myActivity')!(filters),
		};
	}
};

export const generateGraphQLRequest = (
	product: string,
	filters: ActivityFilters,
	activityQueries: ActivityQuery[],
	fragments: string,
): GraphQLRequest => {
	const {
		// we want to default limit if not applied
		limit = ACTIVITIES_LIMIT,
		...restFilters
	} = filters;

	const { query, queryFilters } = generateActivityQueryWithFilters(
		product,
		restFilters,
		activityQueries,
		fragments,
	);

	return {
		operationName: getOperationName(product),
		query,
		variables: {
			first: limit,
			filter: queryFilters,
		},
	};
};
