const urlRegex = new RegExp(/^https?:\/\//);

export function getFullUrl(
	path: string,
	// Add this param so we can test this function better
	hostConfig = {
		host: window.location.hostname,
		port: window.location.port,
	},
) {
	// always return a just the URL if one is provided
	if (urlRegex.test(path)) {
		return path;
	}
	return hostConfig.host === 'localhost'
		? `http://${hostConfig.host}:${hostConfig.port}${path}`
		: path;
}
