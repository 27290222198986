export interface HydratorErrorProps {
	error?: unknown;
	message: string;
}

export default class HydratorError extends Error {
	originalName: string;
	originalMessage: string;
	stack?: string;

	constructor({ error, message }: HydratorErrorProps) {
		super();
		const err = error instanceof Error ? error : new Error();
		this.name = 'HydratorError';
		this.message = message;
		this.originalMessage = err.message;
		this.stack = err.stack;
		this.originalName = err.name;
	}
}
