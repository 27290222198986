import { type ActivityFilters } from '../../../../../common/types';
import { type ActivityQueryArgumentsV2 } from '../../../../../common/types/options/activity-filters';
import { type ActivityFilterGenerator, type ActivityQueryFilterV2 } from '../../index';
import { generateActivityFiltersV2 } from '../utils';

/**
 * This is the query builder for the v2 - activities API
 *
 * @param filters
 */
const generateV2ActivitiesFilters: ActivityFilterGenerator<
	ActivityQueryArgumentsV2,
	ActivityQueryFilterV2
> = (filters: ActivityFilters = {}) => {
	const {
		// discard as these aren't supported by this query
		limit,

		// TODO: Enable the below code as we support multi-user queries and enable them in the main ActivityFilters interface
		// actors,
		// rootContainerIds,
		...restFilters
	} = filters;

	return generateActivityFiltersV2<ActivityQueryArgumentsV2, ActivityQueryFilterV2>(restFilters);
};

export default generateV2ActivitiesFilters;
